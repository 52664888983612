@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
}

:root {
  --color-1: rgb(0, 0, 0);
  --background-color-1: #ffffff;
  --primary-color: #538BFB;
  --background-boxcolor: #23262F;
  --color-2: #17BE9E;
  --color-3: #BE1717;
  --color-4: #0075FF;
  --background-last-color: #E0F7FA;
  --background-footer-color: #1C1E27;
  --background-color-3: rgb(245, 245, 245);
  --background-sign: #f5eded;
  --backdround-sign-btn: #162D3A;
  --background-color-7: #063b81a7;
}

.custom-otp-1 input {
  width: 2rem !important;
  margin: 20px 10px;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  border: 1px solid #e98d9d;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-header {
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
}

.dropdown-header .dropdown-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.dropdown-arrow.open {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown-list {
  position: absolute;
  top: calc(100% + 5px);
  /* Add some space between the input and the dropdown list */
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-item .dropdown-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.section {
  padding: 4rem 2rem;
  text-align: center;
}

.section .error {
  font-size: 150px;
  color: #e98d9d;
  text-shadow:
    1px 1px 1px #e98d9d,
    2px 2px 1px #e98d9d,
    3px 3px 1px #e98d9d,
    4px 4px 1px #e98d9d,
    5px 5px 1px #e98d9d,
    6px 6px 1px #e98d9d,
    7px 7px 1px #e98d9d,
    8px 8px 1px #e98d9d,
    25px 25px 8px rgba(0, 0, 0, 0.2);
}

.page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.back-home {
  display: inline-block;
  border: 2px solid #e98d9d;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0, 0, 0, 0.4);
  background: #e98d9d;
  border-radius: 6px;
  text-decoration: none;
}

.back-home:hover {
  background: #e98d9d;
  color: white;
}



.invoice-container {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  font-family: Arial, sans-serif;
}

header,
.customer-info,
.invoice-details,
.invoice-table,
.invoice-total,
footer {
  margin-bottom: 20px;
}

header h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.company-info h2 {
  margin: 0;
  font-size: 18px;
}

.customer-info h3 {
  margin: 0 0 10px 0;
}

.invoice-details div {
  display: flex;
  justify-content: space-between;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.invoice-table th {
  background-color: #f7f7f7;
}

.invoice-total {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
}

footer {
  text-align: center;
  font-size: 14px;
  color: #666;
}

.liquidity-btn-1 {
  background-color: #e98d9d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.liquidity-btn-1:hover {
  background-color: #e98d9d;
}

.invoice-container {
 
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #e4e4e4;
  padding-bottom: 10px;
}

.header img {
  max-width: 150px;
}

.company-info {
  text-align: right;
}

.company-info h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.company-info p {
  margin: 5px 0;
  color: #777;
}

.invoice-title {
  text-align: center;
  margin: 20px 0;
  font-size: 28px;
  color: #333;
}

.invoice-details,
.customer-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-details div,
.customer-details div {
  width: 48%;
}

.invoice-details div p,
.customer-details div p {
  margin: 5px 0;
  color: #555;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.product-table th,
.product-table td {
  border: 1px solid #e4e4e4;
  padding: 10px;
  text-align: left;
  color: #555;
  font-size: 12px;
}

.product-table th {
  background-color: #f4f4f4;
  font-size: 13px;
}

.total {
  text-align: right;
  margin-bottom: 20px;
}

.total h3 {
  margin: 0;
  color: #333;
}

.footer {
  text-align: center;
  border-top: 2px solid #e4e4e4;
  padding-top: 10px;
  color: #777;
  font-size: 12px;
}

/* .custom-product-table1{
  overflow: auto;
} */

.custom-product-table1 th {
  overflow: auto;
  white-space: nowrap;
}

.custom-modal-invoice .modal-dialog {
  max-width: 1000px !important;
}